
import React from "react";
import { 
  Container, 
  Row, 
  Col, 
  UncontrolledCarousel 
} from "reactstrap";

const items = [
  {
    src: require("assets/img/theme/img-1-1200x1000.jpg"),
    altText: "",
    caption: "",
    header: ""
  },
  {
    src: require("assets/img/theme/img-2-1200x1000.jpg"),
    altText: "",
    caption: "",
    header: ""
  }
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-white font-weight-light">
                  Frank Correa
                </h1>
                <p className="lead text-white mt-4">
                  Frank Correa is a licensed attorney in both the State of California and New York.  His primary areas of practice include entertainment, intellectual property, and international law.  Mr. Correa is involved in the entertainment industry, both from a legal and business perspective.  He has advised a number of prominent entertainers and has held advisory board positions with companies in both the entertainment and international finance industries.
                  <br></br>
                  <br></br>
                  Throughout his entertainment industry career, Mr. Correa has focused on executive producing, business development and consulting for the music, film, television, special venue and tech sectors.  Coupled with his business role in the entertainment industry, Mr. Correa is a professional sound designer, engineer, editor and 5.1 surround sound mixer.  He has worked for many years with a leading sound designer, on numerous film, television and special venue projects, including the Cork Screw Hill attraction at Busch Gardens, Virginia. Mr. Correa has also consulted various pro audio companies, including a major Japanese electronic company on product design and distribution.
                  <br></br>
                  <br></br>
                  Mr. Correa is also General Counsel of Omni Global Trade, Inc., an international consulting firm that focuses primarily on the regions of Asia, Latin America and Eastern Europe.  Mr. Correa has a JD from the University of California, Berkeley and a BS from the Haas School of Business at the University of California, Berkeley.  Mr. Correa is a licensed Real Estate Broker in the State of California.
                  <br></br>
                </p>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default Carousel;
